import React, { useEffect } from "react";
import { useForm, Controller} from "react-hook-form";
import { TextField, Dropdown, Button, Typography } from '@northwell-health/nw-component-lib'
import { Grid, Box, Paper } from '@mui/material'
import styled from '@emotion/styled'

const Item = styled(Paper)(() => ({
  textAlign: 'left',
  minHeight: '65px',
  lineHeight: '60px',
  maxWidth: '400px',
  marginBottom: '24px'
}));

const minorPageOne = (props) => {
  const { SubmissionState, onFormStateChange } = props;
  const { control, handleSubmit, getValues, setValue, formState: { errors } } = useForm({ mode: 'onBlur' });

  useEffect(() => {
    if (SubmissionState.sectionOneForm) {
    setValue("firstName", SubmissionState.sectionOneForm?.firstName);
    setValue("lastName", SubmissionState.sectionOneForm?.lastName);
    setValue("mInitial",SubmissionState.sectionOneForm?.mInitial);
    setValue("DOBChild",SubmissionState.sectionOneForm?.DOBChild);
    setValue("gender",SubmissionState.sectionOneForm?.gender);
    setValue("streetNumber",SubmissionState.sectionOneForm?.streetNumber);
    setValue("City",SubmissionState.sectionOneForm?.City);
    setValue("State",SubmissionState.sectionOneForm?.State);
    setValue("Zip",SubmissionState.sectionOneForm?.Zip); 
    setValue("relationshiptoPatient",SubmissionState.sectionOneForm?.relationshiptoPatient);
    }
}, []);

  const handleRegistration = (data) => {    
    SubmissionState.sectionOneForm = data;
    SubmissionState.goBackPageTwo = true;
    onFormStateChange();
  };
  
  return (
    <Box className="pageContainer">
      <Grid container
        direction="column"
        justifyContent="space-between"
        alignItems="left">          
          <Grid item xs={12}>
            <Typography variant="h1" style={{ marginTop: 80,marginBottom: 24 }}>Minor’s Information</Typography>
          </Grid>         
          <Grid item xs={12} style={{ marginBottom: 24 }}>
            <Typography>Enter the minor's information details below whom you are requesting proxy access for in the FollowMyHealth portal.</Typography>
          </Grid>
      <form onSubmit={handleSubmit(handleRegistration)}>
      <Grid xs={12}>
        <label className="required"><span className="red">*</span> Required</label>
        </Grid>
        <Grid item xs={12}>
          <Item elevation={0}>
          <Controller
         name="firstName" 
         control={control} 
         render={({ field: { onChange } }) => 
          <TextField id="firstName" onChange={onChange} 
          defaultValue={SubmissionState.sectionOneForm?.firstName}
          label="First name"
          error={errors?.firstName}
          fullWidth
          required 
          />} 
          rules={{ 
            required: true,
            pattern: /^[A-Z a-z]*$/i,
             }} />
    {errors?.firstName?.type === "required" && <p className="red">This field is required</p>}
    {errors?.firstName?.type === "pattern" && <p className="red">Please use alphanumeric characters only</p>} 
    </Item>
  </Grid>
  <Grid item xs={12}>
          <Item elevation={0}>
    <Controller 
        name="lastName" 
        control={control} 
        render={({ field: { onChange } }) => 
          <TextField id="lastName"
          defaultValue={SubmissionState.sectionOneForm?.lastName}
          onChange={onChange} 
          label="Last name" 
          error={errors?.lastName}
          fullWidth
          required />}
          rules={{
             required: true,
             pattern: /^[A-Z a-z]*$/i,
              }} />
    {errors?.lastName?.type === "required" && <p className="red">This field is required</p>}
    {errors?.lastName?.type === "pattern" && <p className="red">Please use alphanumeric characters only</p>}  
    </Item>
    </Grid>
    <Grid item xs={12}>
          <Item elevation={0}>
    <Controller 
        name="mInitial"
        control={control} 
        render={({ field: { onChange } }) => 
          <TextField 
          id="mInitial" 
          onChange={onChange} 
          defaultValue={SubmissionState.sectionOneForm?.mInitial}
          label="Midde initial"
          fullWidth
          error={errors?.mInitial}/>
        }
          rules={{
             pattern: /^[A-Za-z]$/
              }} />
    {errors?.mInitial?.type === "pattern" && <p className="red">Please use a single alphanumeric characters only</p>}  
    </Item>
    </Grid>
    <Grid item xs={12}>
          <Item elevation={0}>
    <Controller 
        name="DOBChild" 
        control={control} 
        render={({ field: { onChange } }) => 
          <TextField id="DOBChild" 
          defaultValue={SubmissionState.sectionOneForm?.DOBChild}
          onChange={onChange} 
          label="Date of birth (MM/DD/YYYY)"
          error={errors?.DOBChild}
          fullWidth
          required />}
          rules={{
            pattern: /^(0[1-9]|1[0-2])\/(0[1-9]|[1-2][0-9]|3[0-1])\/(19\d{2}|20[0-2]\d|20[2-9][0-9]|20[3-9][0-9]|21\d{2})$/,
            required: true
              }} />
    {errors?.DOBChild?.type === "pattern" && <p className="red">Please use numeric characters only, and correct Month/Date/Year values.</p>}
    {errors?.DOBChild?.type === "required" && <p className="red">This field is required</p>}
    </Item>
    </Grid>
    <Grid item xs={12}>
          <Item elevation={0}>
    <Controller
    name="gender"
    control={control}
    render={({ field: { onChange } }) => (
    <Dropdown
    id="gender"
    defaultValue={SubmissionState.sectionOneForm?.gender || []}
    label="Birth gender"
    error={errors?.gender}
    selectOptions={[
      { name: 'Female', value: 'Female' },
      { name: 'Male', value: 'Male'},
      { name: 'Other', value: 'Other' },
    ]}
    onChange={onChange}
    required />)}/>
    {errors?.gender?.type === "required" && <p className="red">This field is required</p>}
    </Item>
    </Grid>
    <Grid item xs={12}>
          <Item elevation={0}>
    <Controller 
        name="streetNumber" 
        control={control} 
        render={({ field: { onChange } }) => 
          <TextField id="streetNumber" 
          defaultValue={SubmissionState.sectionOneForm?.streetNumber}
          onChange={onChange} 
          label="Address"
          error={errors?.streetNumber} 
          fullWidth
          required />}
          rules={{
            required: true,
            pattern: /^[A-Za-z0-9 ]+$/
              }} />
    {errors?.streetNumber?.type === "pattern" && <p className="red">Please use alphanumeric characters only</p>}
    {errors?.streetNumber?.type === "required" && <p className="red">This field is required</p>}
    </Item>
    </Grid>
    <Grid item xs={12}>
          <Item elevation={0}>
    <Controller 
        name="City" 
        control={control} 
        render={({ field: { onChange } }) => 
          <TextField 
          id="City" 
          defaultValue={SubmissionState.sectionOneForm?.City}
          onChange={onChange} 
          label="City" 
          error={errors?.City} 
          fullWidth
          required />}
          rules={{
             required: true,
             pattern: /^[A-Z a-z]*$/i,
              }} />
    {errors?.City?.type === "required" && <p className="red">This field is required</p>}
    {errors?.City?.type === "pattern" && <p className="red">Please use alphanumeric characters only</p>}
    </Item>
    </Grid>
    <Grid item xs={12}>
          <Item elevation={0}>
    <Controller 
        name="State" 
        control={control} 
        render={({ field: { onChange } }) => 
          <TextField id="State"
          defaultValue={SubmissionState.sectionOneForm?.State}
          onChange={onChange} 
          label="State" 
          error={errors?.State} 
          fullWidth
          required />}
          rules={{
             required: true,
             pattern: /^[A-Z a-z]*$/i,
              }} />
    {errors?.State?.type === "required" && <p className="red">This field is required</p>}
    {errors?.State?.type === "pattern" && <p className="red">Please use alphanumeric characters only</p>}
    </Item>
    </Grid>
    <Grid item xs={12}>
          <Item elevation={0}>
    <Controller 
        name="Zip" 
        control={control} 
        render={({ field: { onChange } }) => 
          <TextField 
          id="Zip" 
          onChange={onChange} 
          defaultValue={SubmissionState.sectionOneForm?.Zip}
          label="Zip" 
          error={errors?.Zip} 
          fullWidth
          required />}
          rules={{
             required: true,
             pattern:  /(^\d{5}$)|(^\d{5}-\d{4}$)/
             }} />
    {errors?.Zip?.type === "pattern" && <p className="red">Numeric Values Only</p>}
    {errors?.Zip?.type === "required" && <p className="red">This field is required</p>}
    </Item>
    </Grid>
    <Grid item xs={12}>
          <Item elevation={0}>
    <Controller
      name="relationshiptoPatient"
      control={control}
      render={({ field: { onChange } }) => (
    <Dropdown
      id="relationshiptoPatient"
      defaultValue={SubmissionState.sectionOneForm?.relationshiptoPatient || []}
      label="Relationship to patient"
      selectOptions={[
        { name: 'Parent', value: 'Parent' },
        { name: 'Legal guardian', value: 'Legal guardian' },
        { name: 'Durable power of attorney (DPOA)', value: 'Durable power of attorney (DPOA)'},
      ]}
    onChange={onChange}
    error={errors?.relationshiptoPatient} 
    required />
    )}/>
    {errors?.relationshiptoPatient?.type === "required" && <p className="red">This field is required</p>}
    </Item>
    </Grid>
    <Grid>
          <Item elevation={0}>
    <Button text="Continue" color="primary" type="submit" />
    </Item>
    </Grid>
    </form>
    </Grid>
    </Box>
      );
    };
  export default minorPageOne;
   