import React from 'react';
import { HashRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import GigyaLogin from '../components/gigyalogin/gigyalogin';
import MinorProxyForm from '../components/minorProxyForm/minorProxyForm';
import EmailNotification from '../components/emailNotification/emailNotification';
import AdultProxyForm from '../components/adultproxyform/adultproxyform';
import MinorProxyFormNoGigya from '../components/minorProxyForm/minorFormNoGigya';
import PHQ2VerificationForm from '../components/PHQ2/verification-form';
import ErrorPage from '../components/errorpage/errorPage';
import envConfig from "../utils/configEnv/config";

const ToggleAdult = envConfig.REACT_APP_FEATURE_TOGGLE_ADULTPROXY;
const ToggleNewMinorPage = envConfig.REACT_APP_FEATURE_TOGGLE_MINOR;
const TogglePHQ2 = envConfig.REACT_APP_FEATURE_TOGGLE_PHQ2;

// Protected Route Component ensure routes are not toggled off 
const ProtectedRoute = ({ element, condition }) => {
  return condition ? element : <Navigate to="/Error/403 Forbidden" replace />;
};

const AppRoutes = () => (
  <Router basename="/">
    <Routes>
      <Route path="/" element={<GigyaLogin />} />
      <Route path="/minorProxyFormCont/:id" element={<MinorProxyForm />} />
      <Route
        path="/AdultProxyForm/:id?"
        element={
          <ProtectedRoute
            element={<AdultProxyForm />}
            condition={ToggleAdult === 'false'}
          />
        }
      />   
      <Route
        path="/MinorProxyForm/:id?"
        element={
          <ProtectedRoute
            element={<MinorProxyFormNoGigya />}
            condition={ToggleNewMinorPage === 'false'}
          />
        }
      />
       <Route path="/PHQ2"
        element={
          <ProtectedRoute
            element={<PHQ2VerificationForm />}
            condition={TogglePHQ2 === 'false'}
          />
        }
      />
      <Route
        path="/EmailNotification/:email"
        element={<EmailNotification />}
      />
      {/* Eror Page Takes string Value for errormsg or code*/}
      <Route path="/Error/:error" element={<ErrorPage />} />
      {/*  Undefined Navigation Routes Request */}
      <Route path="*" element={<Navigate to="/Error/404 Not Found" replace />} />
    </Routes>
  </Router>
);

export default AppRoutes;
