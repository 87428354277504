import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import UploadFileControl from "../formcomponents/uploadFileControl";
import { TextField, Button, Typography } from '@northwell-health/nw-component-lib'
import { Grid, Box, Paper } from '@mui/material'
import styled from '@emotion/styled'
const TodayTS = Date.now();

const Item = styled(Paper)(() => ({
  textAlign: 'left',
  minHeight: '65px',
  lineHeight: '60px',
  maxWidth: '400px',
  marginBottom: '24px'
}));

const isValidEmail = email =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );

const minorPageTwo = (props) => {
  useEffect(()=>{
    if(relationshiptoPatient !== "Parent"){
      setIsGuardian(true);
    }else{
      setIsGuardian(false);
      setIsFileUploadSt(true);
    }

    if(SubmissionState.sectionTwoForm){
      setValue("firstNameGuardian",SubmissionState.sectionTwoForm?.firstNameGuardian);
      setValue("lastNameGuardian",SubmissionState.sectionTwoForm?.lastNameGuardian);
      setValue("mInitialGuardian",SubmissionState.sectionTwoForm?.mInitialGuardian);
      setValue("DOBGuardian",SubmissionState.sectionTwoForm?.DOBGuardian);
      setValue("streetNumberGuardian",SubmissionState.sectionTwoForm?.streetNumberGuardian);
      setValue("CityGuardian",SubmissionState.sectionTwoForm?.CityGuardian);
      setValue("StateGuardian",SubmissionState.sectionTwoForm?.StateGuardian);
      setValue("ZipGuardian",SubmissionState.sectionTwoForm?.ZipGuardian);
      setValue("phoneNumberGuardian",SubmissionState.sectionTwoForm?.phoneNumberGuardian);
      setValue("emailguardian",SubmissionState.sectionTwoForm?.emailguardian);
    }
  },[])

  const { SubmissionState, onFormStateChange } = props;
  const { control, handleSubmit, getValues, setValue, formState: { errors } } = useForm({ mode: 'onBlur' });
  const [ isfileUploadSt, setIsFileUploadSt ] = useState(false);
  const [ isGuardian, setIsGuardian ] = useState(false);
  const relationshiptoPatient = SubmissionState?.sectionOneForm.relationshiptoPatient;
  const patientName = SubmissionState.sectionOneForm.lastName  + '_' + SubmissionState.sectionOneForm.firstName + '_' + TodayTS;
  
  const uploadCompleted = function(state){
    setIsFileUploadSt(state);
  }

  const handleEmailValidation = email => {
    const isValid = isValidEmail(email);
    const validityChanged =
     (errors.email && isValid) || (!errors.email && !isValid);
     if (validityChanged) {
   }
   return isValid;
 };

  const handleRegistration = (data) => {
    SubmissionState.goBackPageTwo = false;
    SubmissionState.goPageThree = true;
    SubmissionState.SectionTwoFormTimestamp = TodayTS;
    SubmissionState.sectionTwoForm = data;    
    onFormStateChange();
  };

  const previousPageClick = () => {
    SubmissionState.goBackPageTwo = false;
    SubmissionState.goBackPageOne = true;
    SubmissionState.sectionTwoForm = getValues();
    onFormStateChange();
  };

  return (
    <Box className="pageContainer">
      <Grid container
        direction="column"
        justifyContent="space-between"
        alignItems="left">
      <Grid item xs={12} s={12} md={12}>
        <p className="breadcrumb_nav" onClick={previousPageClick}>&#60; Minor’s information</p>        
     </Grid>
     <Grid xs={12}>
      <Typography variant="h1" style={{ marginBottom: 24 }}>Requestor's information</Typography>
     </Grid>      
     <Grid xs={12} style={{marginBottom: 24}}>
      <Typography>Enter the details of the requestor's information to proceed with the proxy access request for the minor patient.</Typography>
     </Grid>
    <form id="completeFMHminorForm" onSubmit={handleSubmit(handleRegistration)}>
    <Grid xs={12}>
      <label className="required"><span className="red">*</span> Required</label>
    </Grid>
    <Grid item xs={12}>
          <Item elevation={0}>
        <Controller 
        name="firstNameGuardian" 
        control={control} 
        render={({ field: { onChange } }) => 
          <TextField id="firstNameGuardian"
          defaultValue={SubmissionState.sectionTwoForm?.firstNameGuardian}
          onChange={onChange} 
          label="First name"
          error={errors?.firstNameGuardian}
          fullWidth
          required />}
          rules={{
             required: true,
             pattern: /^[A-Z a-z]*$/i,
              }} />
      {errors?.firstNameGuardian?.type === "required" && <p className="red">This field is required</p>}
      {errors?.firstNameGuardian?.type === "pattern" && <p className="red">Please use alphanumeric characters only</p>}
      </Item>
      </Grid>
      <Grid item xs={12}>
          <Item elevation={0}>
            <Controller 
            name="lastNameGuardian" 
            control={control} 
            render={({ field: { onChange } }) => 
          <TextField id="lastNameGuardian"
          defaultValue={SubmissionState.sectionTwoForm?.lastNameGuardian}
          onChange={onChange} 
          label="Last name" 
          error={errors?.lastNameGuardian}
          fullWidth
          required />}
          rules={{
             required: true,
             pattern: /^[A-Z a-z]*$/i,
              }} />
      {errors?.lastNameGuardian?.type === "required" && <p className="red">This field is required</p>}
      {errors?.lastNameGuardian?.type === "pattern" && <p className="red">Please use alphanumeric characters only</p>}
      </Item>
      </Grid>
      <Grid item xs={12}>
          <Item elevation={0}>
      <Controller 
        name="mInitialGuardian"
        control={control} 
        render={({ field: { onChange } }) => 
          <TextField 
          id="mInitialGuardian" 
          onChange={onChange} 
          defaultValue={SubmissionState.sectionTwoForm?.mInitialGuardian}
          label="Midde initial"
          error={errors?.mInitialGuardian} 
          fullWidth/>}
          rules={{
            pattern: /^[A-Za-z]$/
              }} />
      {errors?.mInitialGuardian?.type === "pattern" && <p className="red">Please use alphanumeric characters only</p>}
      </Item>
      </Grid>
      <Grid item xs={12}>
          <Item elevation={0}>
        <Controller 
        name="DOBGuardian" 
        control={control} 
        render={({ field: { onChange } }) => 
          <TextField id="DOBGuardian" 
          defaultValue={SubmissionState.sectionTwoForm?.DOBGuardian}
          onChange={onChange} 
          label="Date of birth (MM/DD/YYYY)"
          error={errors?.DOBGuardian}
          fullWidth
          required />}
          rules={{
            pattern: /^(0[1-9]|1[0-2])\/(0[1-9]|[1-2][0-9]|3[0-1])\/(19\d{2}|20[0-2]\d|20[2-9][0-9]|20[3-9][0-9]|21\d{2})$/,
            required: true
              }} />
      {errors?.DOBGuardian?.type === "pattern" && <p className="red">Please use numeric characters only, and correct Month/Date/Year values.</p>}
      {errors?.DOBGuardian?.type === "required" && <p className="red">This field is required</p>}
      </Item>
      </Grid>
      <Grid item xs={12}>
          <Item elevation={0}>
            <Controller 
            name="streetNumberGuardian" 
            control={control} 
            render={({ field: { onChange } }) => 
          <TextField id="streetNumberGuardian" 
          defaultValue={SubmissionState.sectionTwoForm?.streetNumberGuardian}
          onChange={onChange} 
          label="Address" 
          error={errors?.streetNumberGuardian}
          fullWidth
          required />}
          rules={{
             required: true,
              pattern: /^[A-Za-z0-9 ]+$/
              }} />
              {errors?.streetNumberGuardian?.type === "pattern" && <p className="red">Please use a single alphanumeric characters only</p>}
              {errors?.streetNumberGuardian?.type === "required" && <p className="red">This field is required</p>}
              </Item>
              </Grid>
          <Grid item xs={12}>
          <Item elevation={0}>
            <Controller 
        name="CityGuardian" 
        control={control} 
        render={({ field: { onChange } }) => 
          <TextField 
          id="CityGuardian" 
          defaultValue={SubmissionState.sectionTwoForm?.CityGuardian}
          onChange={onChange}
          label="City"
          error={errors?.CityGuardian}
          fullWidth
          required />}
          rules={{
             required: true,
             pattern: /^[A-Z a-z]*$/i,
              }} />
      {errors?.CityGuardian?.type === "required" && <p className="red">This field is required</p>}
      {errors?.CityGuardian?.type === "pattern" && <p className="red">Please use alphanumeric characters only</p>}
      </Item>
      </Grid>
      <Grid item xs={12}>
          <Item elevation={0}>
 <Controller 
        name="StateGuardian" 
        control={control} 
        render={({ field: { onChange } }) => 
          <TextField id="StateGuardian"
          defaultValue={SubmissionState.sectionTwoForm?.StateGuardian}
          onChange={onChange} 
          label="State"
          error={errors?.StateGuardian}
          fullWidth
          required />}
          rules={{
             required: true,
             pattern: /^[A-Z a-z]*$/i,
              }} />
      {errors?.StateGuardian?.type === "required" && <p className="red">This field is required</p>}
      {errors?.StateGuardian?.type === "pattern" && <p className="red">Please use alphanumeric characters only</p>}      
      </Item>
      </Grid>
      <Grid item xs={12}>
          <Item elevation={0}>
      <Controller 
        name="ZipGuardian" 
        control={control} 
        render={({ field: { onChange } }) => 
          <TextField 
          id="ZipGuardian" 
          onChange={onChange} 
          defaultValue={SubmissionState.sectionTwoForm?.ZipGuardian}
          error={errors?.ZipGuardian}
          label="Zip"
          fullWidth
          required />}
          rules={{
            pattern:  /(^\d{5}$)|(^\d{5}-\d{4}$)/,
             required: true
             }} />
             {errors?.ZipGuardian?.type === "pattern" && <p className="red">Numeric Values Only</p>}
             {errors?.ZipGuardian?.type === "required" && <p className="red">This field is required</p>}
             </Item>
             </Grid>
             <Grid item xs={12}>
          <Item elevation={0}>
      <Controller 
        name="phoneNumberGuardian" 
        control={control} 
        render={({ field: { onChange } }) => 
          <TextField 
          id="phoneNumberGuardian" 
          onChange={onChange} 
          defaultValue={SubmissionState.sectionTwoForm?.phoneNumberGuardian}
          label="Phone"
          error={errors?.phoneNumberGuardian}
          fullWidth
          required />}
          rules={{
             required: true,
             pattern: /^(\+1)?[ -]?\(?([0-9]{3})\)?[ -]?([0-9]{3})[ -]?([0-9]{4})$/
             }} />
      {errors?.phoneNumberGuardian?.type === "pattern" && <p className="red">Numeric Values Only</p>}
      {errors?.phoneNumberGuardian?.type === "required" && <p className="red">This field is required</p>}
      </Item>
      </Grid>
      <Grid item xs={12}>
          <Item elevation={0}>
<Controller 
        name="emailguardian" 
        control={control} 
        render={({ field: { onChange } }) => 
          <TextField id="emailguardian"
           onChange={onChange} 
           defaultValue={SubmissionState.sectionTwoForm?.emailguardian}
           label="Email address"
           error={errors?.emailguardian}
           fullWidth
           required />}
           rules={{
            required: true,
            pattern: /^[A-Z a-z0-9@.!#$%&'*+-/=?^_`{|}~]*$/i,
            maxLength: 122, validate: handleEmailValidation 
              }} />
      {errors?.emailguardian?.type === "required" && <p className="red">This field is required</p>}
      {errors?.emailguardian?.type === "validate" && <p className="red">Email is not in proper format</p>}
      {errors?.emailguardian?.type === "pattern" && <p className="red">Please use alphanumeric characters only</p>}      
      </Item>
      </Grid>      
      </form>      
      <Grid item xs={12}>  
      {isGuardian ?  <div style={{ marginBottom: 24 }}><label>*If you are the patient’s {relationshiptoPatient}, please attach to this form copies of any legal documentation demonstrating that you are the {relationshiptoPatient}, if such documentation is not already on file in the patient’s medical record.</label></div> : null}      
      {isGuardian ?  
      <UploadFileControl 
      patientName={ patientName } 
      uploadStateChange={uploadCompleted}
      formid = {'MinorProxyForm'}/>: null}
      </Grid>
      <Grid item xs={12}>
      <div className="section-three-sign">          
      <form onSubmit={handleSubmit(handleRegistration)}>
      {isGuardian && !isfileUploadSt? <p className="uploadError">You must upload requested files to submit form.</p>: null}
      {isfileUploadSt?  <Button text="Continue" color="primary" type="submit" />: null}
      </form>
      </div>
      </Grid>
    </Grid>
  </Box>
  );
};
export default minorPageTwo;
